import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer"></footer>
    )
  }
}

export default Footer
